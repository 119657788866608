import { Component, OnInit } from '@angular/core';
import { LocalStorageKeys } from '@d-constants/local-storage.enum';
import { ICountryModel, IGetCountriesResponseModel } from '@d-models/country.model';
import { ILocalizationModel } from '@d-models/selected-localization.model';
import { DEFAULT_LANGUAGE_CODE } from '@d-well-know-types/app.constants';
import { Continents } from '@d-well-know-types/continents.enum';
import { CountryService } from 'app/shared/services/country.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import packageInfo from '../../../../package.json';
import { FeatureFlagService, FlagEvents } from '../../shared/services/feature-flag.service';
import { FF_CA, FF_DE, FF_GB, FF_IE, FF_JP, FF_KR, FF_US, FF_GG, FF_JE, FF_IM } from '@d-constants/feature-flag.constants';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { LegalService } from 'app/shared/services/legal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  localization!: ILocalizationModel | null;
  americaCountries: ICountryModel[] = [];
  latinAmericaCountries: ICountryModel[] = [];
  europeCountries: ICountryModel[] = [];
  asiaPacificCountries: ICountryModel[] = [];
  countryGroups: {
    continent: string;
    countries: ICountryModel[];
  }[] = [];

  expectedCountries: string[] = [FF_CA, FF_DE, FF_GB, FF_IE, FF_JP, FF_KR, FF_US, FF_GG, FF_JE, FF_IM];
  availableCountries$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(['US']);
  availableCountries: string[] = [];
  defaultCountry = {
    id: 36,
    countryCode: 'US',
    countryName: 'US',
    continentName: 'The Americas',
  };

  constructor(
    private countryService: CountryService,
    private localStorageService: LocalStorageService,
    private featureFlagService: FeatureFlagService,
    private legalsService: LegalService
  ) {
    this.featureFlagService.events$.subscribe({
      next: (x: FlagEvents) => {
        let availableCountries: string[] = [];
        this.expectedCountries.forEach(country => {
          if (this.featureFlagService.isEnabled(country)) {
            availableCountries.push(country.substring(8).toUpperCase());
          }
        });
        this.availableCountries$.next(availableCountries);
      },
    });

    this.featureFlagService.update();
  }

  ngOnInit(): void {
    this.localStorageService.$localization.subscribe(lo => {
      if (lo) {
        this.localization = lo;
      }
      this.localization = this.localStorageService.getLocalization();
    });

    this.availableCountries$.subscribe((availableCountries: any) => {
      this.availableCountries = availableCountries;
      this.getCountries();
    });
  }

  async onCountrySelect(country: ICountryModel) {
    let selectedLanguageCode = this.localization?.currentLanguageCode;

    if (country.countryCode !== this.localization?.currentCountryCode &&
      selectedLanguageCode !== DEFAULT_LANGUAGE_CODE
    ) {
      localStorage.removeItem(`${LocalStorageKeys.Localization}_${packageInfo.version}`);

      // get languages of the selected country
      const res = await lastValueFrom(
        this.legalsService.getLanguagesBasedOnCountry(country.countryCode)
      );
      const selectedCountrySupportedLanguages = res.languages;
    
      // if the selected language code is not supported on the selected country,
      // the default will be used
      selectedLanguageCode = selectedCountrySupportedLanguages.some(e => e.languageCode == this.localization?.currentLanguageCode)
        ? this.localization?.currentLanguageCode
        : DEFAULT_LANGUAGE_CODE;
    } 

    const url = `introduce/${selectedLanguageCode}/${country.countryCode}`;
    location.href = url;
  }

  getCountries() {
    this.countryService.getCountries().subscribe((res: IGetCountriesResponseModel) => {
      this.groupingCountries(res);
    });
  }

  groupingCountries(res: any) {
    this.countryGroups = [];
    if (res.countries && res.countries.length > 0) {
      let americasCountries = res.countries.filter(
        (c: any) => c.continentName === Continents.America && this.availableCountries.includes(c.countryCode)
      );
      if (americasCountries.length > 0) {
        this.countryGroups.push({
          continent: 'ridevuWording.home-americas | default | Americas',
          countries: americasCountries,
        });
      }
      let latinAmericasCountries = res.countries.filter(
        (c: any) => c.continentName === Continents.LatinAmerica && this.availableCountries.includes(c.countryCode)
      );
      if (latinAmericasCountries.length > 0) {
        this.countryGroups.push({
          continent: 'ridevuWording.home-latin-americas | default | Latin Americas',
          countries: latinAmericasCountries,
        });
      }
      let europeCountries = res.countries.filter(
        (c: any) => c.continentName === Continents.Europe && this.availableCountries.includes(c.countryCode)
      );
      if (europeCountries.length > 0) {
        this.countryGroups.push({
          continent: 'ridevuWording.home-europe | default | Europe',
          countries: europeCountries,
        });
      }
      let asiaPacificCountries = res.countries.filter(
        (c: any) => c.continentName === Continents.AsiaPacific && this.availableCountries.includes(c.countryCode)
      );
      if (asiaPacificCountries.length > 0) {
        this.countryGroups.push({
          continent: 'ridevuWording.home-asia-pacific | default | Asia Pacific',
          countries: asiaPacificCountries,
        });
      }
    }
  }
}
